(() => {
    const form = document.querySelector('#event-form');

    if (!form) {
        return;
    }

    const participationButtons = [...form.querySelectorAll('input[name="participation"]')];

    if (participationButtons && participationButtons.length && participationButtons.length === 2) {
        const optOutButton = participationButtons[1];
        const elementsToHide = [...form.querySelectorAll('.participation-only')];

        const changeHandler = () => {
            if (optOutButton.checked) {
                elementsToHide.forEach((element) => {
                    element.style.display = 'none';
                    const inputs = [...element.querySelectorAll('input')];
                    inputs.forEach((input) => {
                        input.required = false;
                    });
                });
            } else {
                elementsToHide.forEach((element) => {
                    element.style.display = '';
                    if (element.classList.contains('mandatory')) {
                        const inputs = [...element.querySelectorAll('input')];
                        inputs.forEach((input) => {
                            input.required = true;
                        });
                    }
                });
            }
        };

        participationButtons.forEach((button) => {
            button.addEventListener('change', changeHandler);
        });
    }
})();
