(() => {
    const fieldsets = document.querySelectorAll('.radio_container.buttons');

    if (!fieldsets) {
        return;
    }

    for (const fieldset of fieldsets) {
        const spans = fieldset.querySelectorAll('.radio_container.buttons > span');

        const updateLabels = () => {
            for (const span of spans) {
                const input = span.querySelector('input');
                const label = span.querySelector('label');

                if (input.checked && !label.classList.contains('checked')) {
                    label.classList.add('checked');
                } else if (!input.checked && label.classList.contains('checked')) {
                    label.classList.remove('checked');
                }
            }
        };
        updateLabels();

        const inputs = fieldset.querySelectorAll('input');
        for (const input of inputs) {
            input.addEventListener('change', () => {
                updateLabels();
            });
        }
    }
})();
