(() => {
    const collapsibles = document.querySelectorAll('.collapsible');

    if (!collapsibles) {
        return;
    }

    for (const collapsible of collapsibles) {
        const trigger = collapsible.querySelector('h2') || collapsible.querySelector('h3');

        trigger.addEventListener('click', () => {
            if (collapsible.classList.contains('collapsed')) {
                collapsible.classList.remove('collapsed');
            } else {
                collapsible.classList.add('collapsed');
            }
        });
    }
})();
