// Search bar in header
(() => {
    const header = document.querySelector('header');
    const div = header.querySelector('header .ce_form');
    const input = div.querySelector('header .ce_form input');

    if (!input) {
        return;
    }

    // Register event handler
    input.addEventListener('focus', () => {
        if (!header.classList.contains('search-active')) {
            header.classList.add('search-active');
        }
        if (!div.classList.contains('active')) {
            div.classList.add('active');
        }
    });
    input.addEventListener('blur', () => {
        if (header.classList.contains('search-active')) {
            header.classList.remove('search-active');
        }
        if (div.classList.contains('active')) {
            div.classList.remove('active');
        }
    });
    input.addEventListener('keyup', (event) => {
        if (event.which == 27) {
            // Esc
            event.preventDefault();
            input.blur();
        }
    });
})();

// Search page
(() => {
    const radioList = document.querySelectorAll('.mod_custom_search input[name=parent_page]');

    if (!radioList || radioList.length < 1) {
        return;
    }

    for (const radio of radioList) {
        radio.addEventListener('change', () => {
            radio.form.submit();
        });
    }
})();
