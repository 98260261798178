(() => {
    const trigger = document.querySelector('#legal-settings .controls a');
    const formSheet = document.querySelector('#legal-settings .form');
    const header = document.querySelector('header');
    const mobileMenuTrigger = document.querySelector('#mobile-header .menu-trigger');

    if (trigger && formSheet) {
        const hideFormSheet = () => {
            formSheet.classList.remove('visible');
            header.classList.remove('legal-settings');

            const overlay = document.querySelector('body > .overlay');
            if (overlay) {
                document.querySelector('body').removeChild(overlay);
            }
        };

        const showFormSheet = (modal) => {
            const overlay = document.createElement('div');
            overlay.className = 'overlay';
            document.querySelector('body').appendChild(overlay);

            if (!modal) {
                overlay.addEventListener('click', () => {
                    hideFormSheet();
                });

                mobileMenuTrigger.addEventListener('click', () => {
                    hideFormSheet();
                });
            }

            formSheet.classList.add('visible');
            header.classList.add('legal-settings');

            if (!header.classList.contains('visible')) {
                header.classList.add('visible');
            }

            updateLegalTexts();
        };

        const updateLegalTexts = () => {
            const typeElement = formSheet.querySelector('input[name=type]:checked');
            const regionElement = formSheet.querySelector('input[name=region]:checked');
            const submitElement = formSheet.querySelector('.widget-submit button');

            if (typeElement && regionElement) {
                const type = typeElement.value;
                const region = regionElement.value;
                const texts = formSheet.querySelectorAll('.legal-text');

                for (const text of texts) {
                    if (text.classList.contains(type) && text.classList.contains(region)) {
                        if (!text.classList.contains('visible')) {
                            text.classList.add('visible');
                        }
                    } else {
                        if (text.classList.contains('visible')) {
                            text.classList.remove('visible');
                        }
                    }
                }
            }
        };

        trigger.addEventListener('click', (event) => {
            event.preventDefault();

            if (formSheet.classList.contains('visible')) {
                hideFormSheet();
            } else {
                showFormSheet();
                updateLegalTexts();
            }
        });

        for (const element of formSheet.querySelectorAll('input[type=radio]')) {
            element.addEventListener('click', () => {
                updateLegalTexts();
            });
        }

        // Popup if mandatory and not yet confirmed
        const mandatory = document.querySelector('body').classList.contains('legal-settings-mandatory');
        const confirmed = document.querySelector('#legal-settings').classList.contains('visible');
        const valid = document.querySelector('#legal-settings').classList.contains('valid');
        if (mandatory && !confirmed | !valid) {
            showFormSheet(true);
        }
    }
})();
