// Requires jQuery

(() => {
    const links = document.querySelectorAll('a.translated-download');
    let switches = {};

    for (const link of links) {
        const translatedBy = link.dataset.translatedBy;

        if (!translatedBy) {
            continue;
        }

        if (!switches[translatedBy]) {
            switches[translatedBy] = {
                element: document.querySelector('#' + translatedBy),
                links: [],
            };
        }

        switches[translatedBy].links.push(link);

        const language = switches[translatedBy].element.options[switches[translatedBy].element.selectedIndex].value;
        const href = link.dataset['href' + language.substr(0, 1).toUpperCase() + language.substr(1)];

        link.href = href;
    }

    for (const sw in switches) {
        if (switches.hasOwnProperty(sw)) {
            $(switches[sw].element).on('change', () => {
                // Event is triggered with jQuers, so only jQuery works here
                const language = switches[sw].element.options[switches[sw].element.selectedIndex].value;

                for (const link of switches[sw].links) {
                    const href = link.dataset['href' + language.substr(0, 1).toUpperCase() + language.substr(1)];
                    link.href = href;
                }
            });
        }
    }

    const selects = document.querySelectorAll('select.translated-download');

    for (const select of selects) {
        $(select).on('change', () => {
            // Event is triggered with jQuers, so only jQuery works here
            const href = select.options[select.selectedIndex].value;

            if (href && href.length > 0) {
                window.open(href);
            }
        });
    }
})();
