// Requires jQuery

(() => {
    $('.labels button:not(:first)').addClass('inactive');
    $('.tab_content').hide();
    $('.tab_content:first').show();

    $('.labels button').click(function () {
        var t = $(this).attr('id');
        if ($(this).hasClass('inactive')) {
            //this is the start of our condition
            $('.labels button').addClass('inactive');
            $(this).removeClass('inactive');

            $('.tab_content').hide();
            $('#' + t + 'C').fadeIn('slow');
        }
    });

    /*Mobile VErsion*/
    $('.quick_nav_mobile .dropbtn').click(function () {
        $(this).parent().find('.dropdown-content').toggleClass('collapsed');
    });

    $('.quick_nav_mobile .dropdown-content button').addClass('inactive');

    $('.quick_nav_mobile button:not(:first)').click(function () {
        var t = $(this).attr('id');
        if ($(this).hasClass('inactive')) {
            //this is the start of our condition
            $('.quick_nav_mobile button').addClass('inactive');
            $(this).removeClass('inactive');

            $('.tab_content').hide();
            $('#' + t + 'C')
                .fadeIn('slow')
                .find('.text')
                .addClass('show_content');
        }
    });
})();
