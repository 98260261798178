(() => {
    const scrollables = document.querySelectorAll('.scrollable');

    if (!scrollables) {
        return;
    }

    for (const scrollable of scrollables) {
        // Setup DOM
        const wrapper = document.createElement('div');
        wrapper.className = scrollable.className;
        wrapper.classList.add('wrapper');
        scrollable.parentNode.insertBefore(wrapper, scrollable);

        scrollable.className = 'scrollable';
        wrapper.appendChild(scrollable);

        const handler = document.createElement('div');
        handler.className = 'handler';
        wrapper.appendChild(handler);

        // Handle scroll events
        const handlerSize = handler.clientHeight || 28;
        scrollable.addEventListener('scroll', () => {
            handler.style.top = 'calc(-' + handlerSize / 2 + 'px + ' + (100 * scrollable.scrollTop) / (scrollable.scrollHeight - handlerSize) + '%)';
        });

        // Setup handler dragging
        let cursorY = 0;
        let handlerY = 0;

        const mouseMove = (event) => {
            event.preventDefault();
            const dY = event.clientY - cursorY;
            handlerY += dY;
            if (handlerY >= handlerSize / -2 && handlerY <= scrollable.clientHeight - handlerSize / 2) {
                const scrollTop = ((handlerY + handlerSize / 2) / scrollable.clientHeight) * scrollable.scrollHeight;
                scrollable.scrollTop = scrollTop;
                cursorY = event.clientY;
            }
        };

        const mouseUp = (event) => {
            event.preventDefault();
            handler.removeEventListener('mousemove', mouseMove);
            handler.removeEventListener('mouseup', mouseUp);
            handler.removeEventListener('mouseout', mouseUp);
        };

        handler.addEventListener('mousedown', (event) => {
            event.preventDefault();
            cursorY = event.clientY;
            handlerY = handler.offsetTop;

            handler.addEventListener('mousemove', mouseMove);
            handler.addEventListener('mouseup', mouseUp);
            handler.addEventListener('mouseout', mouseUp);
        });
    }
})();
