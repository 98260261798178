(() => {
    const parent = document.querySelector('.ce_fonds_overview');
    if (!parent) {
        return;
    }

    const allSubsTrigger = parent.querySelector('.toggle-all-subs');
    const allSubs = parent.querySelectorAll('.sub');

    // Update toggle-all triger
    const updateTriggerLayout = () => {
        if (allSubsTrigger) {
            var hasClosed = false;
            for (const sub of allSubs) {
                if (sub.classList.contains('collapsed')) {
                    hasClosed = true;
                    break;
                }
            }

            if (hasClosed) {
                if (allSubsTrigger.classList.contains('close')) {
                    allSubsTrigger.classList.remove('close');
                }
            } else {
                if (!allSubsTrigger.classList.contains('close')) {
                    allSubsTrigger.classList.add('close');
                }
            }
        }
    };

    // Register sub triggers
    const subTriggers = parent.querySelectorAll('.toggle-sub-handle');
    for (const trigger of subTriggers) {
        const dataID = trigger.dataset.sub;
        const sub = parent.querySelector('#sub-' + dataID);

        if (sub) {
            trigger.addEventListener('click', () => {
                if (sub.classList.contains('collapsed')) {
                    sub.classList.remove('collapsed');

                    if (trigger.classList.contains('collapsed')) {
                        trigger.classList.remove('collapsed');
                    }
                } else {
                    sub.classList.add('collapsed');

                    if (!trigger.classList.contains('collapsed')) {
                        trigger.classList.add('collapsed');
                    }
                }

                updateTriggerLayout();
            });
        }
    }

    // Register toggle all subs
    if (allSubsTrigger) {
        allSubsTrigger.addEventListener('click', () => {
            let allOpen = true;
            for (const sub of allSubs) {
                if (sub.classList.contains('collapsed')) {
                    allOpen = false;
                    break;
                }
            }

            for (const sub of allSubs) {
                if (allOpen) {
                    if (!sub.classList.contains('collapsed')) {
                        sub.classList.add('collapsed');
                    }
                } else {
                    if (sub.classList.contains('collapsed')) {
                        sub.classList.remove('collapsed');
                    }
                }
            }

            for (const trigger of subTriggers) {
                if (allOpen) {
                    if (!trigger.classList.contains('collapsed')) {
                        trigger.classList.add('collapsed');
                    }
                } else {
                    if (trigger.classList.contains('collapsed')) {
                        trigger.classList.remove('collapsed');
                    }
                }
            }

            updateTriggerLayout();
        });
    }

    // Set up filter form
    const form = document.querySelector('form.fonds-overview');
    if (form) {
        const radios = form.querySelectorAll('input[type=radio],input[type=checkbox]');

        function filter() {
            let filter = {};

            for (const radio of radios) {
                const name = radio.name.replace('[]', '');

                if (!filter[name]) {
                    filter[name] = [];
                }

                if (radio.checked) {
                    filter[name].push(radio.value);
                }
            }

            const innerTables = parent.querySelectorAll('table.inner');
            for (const table of innerTables) {
                const id = table.dataset.id;
                const headRow = parent.querySelector('#head-' + id);
                const subRow = parent.querySelector('#sub-' + id);
                const counter = parent.querySelector('#counter-' + id);
                const rows = table.querySelectorAll('tr.datarow');

                for (const row of rows) {
                    let display = true;
                    const cols = row.querySelectorAll('td');
                    for (const col of cols) {
                        const key = col.dataset.key;
                        const value = col.dataset.value;

                        if (key) {
                            const values = filter[key];

                            if (values && values.length > 0 && values.indexOf(value) < 0) {
                                display = false;
                            }
                        }

                        if (display && row.classList.contains('filtered')) {
                            row.classList.remove('filtered');
                        } else if (!display && !row.classList.contains('filtered')) {
                            row.classList.add('filtered');
                        }
                    }
                }

                let rowCount = 0;
                for (const row of rows) {
                    if (!row.classList.contains('filtered')) {
                        rowCount++;
                        if (rowCount % 2 == 0 && row.classList.contains('odd')) {
                            row.classList.remove('odd');
                            row.classList.add('even');
                        } else if (rowCount % 2 == 1 && row.classList.contains('even')) {
                            row.classList.remove('even');
                            row.classList.add('odd');
                        }
                    }
                }

                if (counter) {
                    counter.innerHTML = rowCount;
                }

                if (headRow) {
                    if (rowCount > 0 && headRow.classList.contains('obsolete')) {
                        headRow.classList.remove('obsolete');
                        subRow.classList.remove('obsolete');
                    } else if (rowCount == 0 && !headRow.classList.contains('obsolete')) {
                        headRow.classList.add('obsolete');
                        subRow.classList.add('obsolete');
                    }
                }
            }
        }

        for (const radio of radios) {
            radio.addEventListener('click', () => {
                filter();
            });
        }

        // Register filter reset
        const resetTrigger = document.querySelector('.reset-filters');
        if (resetTrigger) {
            resetTrigger.addEventListener('click', () => {
                for (const radio of radios) {
                    radio.checked = false;
                    filter();
                }
            });
        }

        // Filter on page load
        filter();
    }
})();
