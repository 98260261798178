// Requires jQuery

(() => {
    document.addEventListener('DOMContentLoaded', () => {
        window.setTimeout(() => {
            const analyticsCheckbox = document.querySelector('.cookiebar__analytics-checkbox');
            const button = document.querySelector('.cookiebar__button');

            if (analyticsCheckbox && button) {
                analyticsCheckbox.addEventListener('change', () => {
                    window.setTimeout(() => {
                        $(button).click();
                    }, 0);
                });
            }
        }, 0);
    });
})();
