(() => {
    // Register click handler on trigger
    const trigger = document.querySelector('#mobile-header .menu-trigger');
    const header = document.querySelector('header');
    if (trigger && header) {
        trigger.addEventListener('click', () => {
            if (header.classList.contains('visible')) {
                header.classList.remove('visible');
            } else {
                header.classList.add('visible');
            }
        });
    }

    // Register click handler on nav
    const nav = document.querySelector('#nav-main');
    if (nav) {
        for (const element of nav.querySelectorAll('.level_1 > li > strong')) {
            element.addEventListener('click', (event) => {
                if (element.parentElement.classList.contains('open')) {
                    element.parentElement.classList.remove('open');
                } else {
                    element.parentElement.classList.add('open');
                }
            });
        }
    }
})();
