(() => {
    const containers = document.querySelectorAll('.ce_sliderStart');

    for (const container of containers) {
        // Get slider
        const slider = container.querySelector('.content-slider');

        // Get config
        const [interval, transitionDuration, offset, repeat] = (slider.dataset.config || '1000,300,0,1').split(',').map((item) => parseInt(item));

        // Get controls
        const controls = container.querySelector('.slider-control');
        const currentSlideLabel = controls.querySelector('.current-slide');
        const totalSlidesLabel = controls.querySelector('.total-slides');

        // Setup elements
        const originalElements = slider.querySelectorAll('.slider-wrapper > *');
        const totalSlides = originalElements.length;

        if (totalSlides === 1) {
            controls.style.display = 'none';
            continue;
        }

        let firstSlide = 0;
        let lastSlide = originalElements.length - 1;

        if (repeat && totalSlides > 0) {
            // Clone first element
            originalElements[0].parentNode.appendChild(originalElements[0].cloneNode(true));
            firstSlide = 1;
            lastSlide = lastSlide + firstSlide;

            // Clone last element
            originalElements[0].parentNode.prepend(originalElements[originalElements.length - 1].cloneNode(true));
        }

        const elements = slider.querySelectorAll('.slider-wrapper > *');

        var currentSlide = firstSlide + offset;

        // Init labels
        if (totalSlidesLabel) {
            totalSlidesLabel.innerHTML = totalSlides;
        }

        // Update slide
        function setSlide(slideIndex, duration) {
            if (slideIndex < 0) {
                if (repeat) {
                    window.requestAnimationFrame(() => {
                        setSlide(lastSlide, 0);
                        window.requestAnimationFrame(() => {
                            setSlide(lastSlide - 1, duration);
                        });
                    });
                }

                return;
            } else if (slideIndex > lastSlide + 1) {
                if (repeat) {
                    window.requestAnimationFrame(() => {
                        setSlide(firstSlide, 0);
                        window.requestAnimationFrame(() => {
                            setSlide(firstSlide + 1, duration);
                        });
                    });
                }

                return;
            } else {
                currentSlide = slideIndex;
            }

            for (const index of elements.keys()) {
                const element = elements[index];
                element.style.transition = `transform ${duration}ms`;
                element.style.transform = `translate(${(index - currentSlide) * 100}%)`;
            }

            // Update labels
            if (currentSlideLabel) {
                if (currentSlide > totalSlides) {
                    currentSlideLabel.innerHTML = 1;
                } else if (currentSlide < firstSlide) {
                    currentSlideLabel.innerHTML = totalSlides;
                } else {
                    currentSlideLabel.innerHTML = currentSlide + 1 - firstSlide;
                }
            }
        }

        // Init slides
        setSlide(currentSlide, 0);

        // Setup timer
        const setTimer = () => {
            return window.setInterval(() => {
                if (currentSlide < originalElements.length - 1 || repeat) {
                    setSlide(currentSlide + 1, transitionDuration);
                } else {
                    window.clearInterval(timer);
                }
            }, interval);
        };
        let timer = setTimer();

        // Setup control handlers
        const previousTrigger = controls.querySelector('.slider-prev');
        const nextTrigger = controls.querySelector('.slider-next');

        const previous = (duration) => {
            duration = duration === undefined ? transitionDuration : duration;
            window.clearInterval(timer);
            setSlide(currentSlide - 1, duration);
            timer = setTimer();
        };

        const next = (duration) => {
            duration = duration === undefined ? transitionDuration : duration;
            window.clearInterval(timer);
            setSlide(currentSlide + 1, duration);
            timer = setTimer();
        };

        if (previousTrigger) {
            previousTrigger.addEventListener('click', (event) => {
                event.preventDefault();
                previous();
            });
        }

        if (nextTrigger) {
            nextTrigger.addEventListener('click', (event) => {
                event.preventDefault();
                next();
            });
        }

        // Setup keyboard control
        window.addEventListener('keydown', (event) => {
            switch (event.which) {
                case 37:
                    previous();
                    break;
                case 39:
                    next();
                    break;
            }
        });

        // Setup touch control
        let catchEvents = false;
        let touchStartX = null;
        let touchStartY = null;
        let dX = 0;
        let touchStartTime = null;
        let touchEndTime = null;

        slider.addEventListener('touchstart', (event) => {
            window.clearInterval(timer);
            touchStartX = event.changedTouches[0].pageX;
            touchStartY = event.changedTouches[0].pageY;
            touchStartTime = Date.now();

            catchEvents = null; // we don't know yet
        });

        slider.addEventListener('touchmove', (event) => {
            dX = event.changedTouches[0].pageX - touchStartX;
            let dY = event.changedTouches[0].pageY - touchStartY;

            // Get direction
            if (catchEvents === null) {
                catchEvents = Math.abs(dX) > Math.abs(dY);
            }

            if (catchEvents === true) {
                event.preventDefault();
            } else {
                return;
            }

            // Fix loop
            if (repeat && currentSlide == 0 && dX > 0) {
                currentSlide = lastSlide;
            } else if (repeat && currentSlide > lastSlide && dX < 0) {
                currentSlide = firstSlide;
            }

            for (const index of elements.keys()) {
                const element = elements[index];
                element.style.transition = `transform 0ms`;
                element.style.transform = `translate(calc(${(index - currentSlide) * 100}% + ${dX}px))`;
            }
        });

        const restore = () => {
            const halfWidth = window.innerWidth / 2;

            touchEndTime = Date.now();

            const speed = Math.abs(dX / (touchEndTime - touchStartTime));
            const speedThreshold = 0.4;

            if (dX > 0 && (dX > halfWidth || speed > speedThreshold)) {
                previous(transitionDuration / 2);
            } else if (dX < 0 && (dX < -halfWidth || speed > speedThreshold)) {
                next(transitionDuration / 2);
            } else {
                window.clearInterval(timer);
                setSlide(currentSlide, transitionDuration / 2);
                timer = setTimer();
            }

            touchStartX = null;
            touchStartY = null;
            dX = 0;
        };

        slider.addEventListener('touchend', (event) => {
            if (catchEvents === true) {
                event.preventDefault();
            } else {
                return;
            }

            restore();
        });

        slider.addEventListener('touchcancel', (event) => {
            if (catchEvents === true) {
                event.preventDefault();
            } else {
                return;
            }

            restore();
        });
    }
})();
