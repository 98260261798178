(() => {
    const canvas = document.querySelector('.ce_share_class_chart canvas.chart');

    if (!canvas) {
        return;
    }

    const parent = document.querySelector('.ce_share_class_chart').parentNode;

    let isSetup = false;

    const setup = () => {
        const shareClassID = canvas.dataset.shareClass;

        const setCursor = (() => {
            const cursorCanvas = document.querySelector('.ce_share_class_chart canvas.cursor');
            if (cursorCanvas) {
                return (x, clear) => {
                    const width = parseInt(canvas.style.width || canvas.width);
                    const height = parseInt(canvas.style.height || canvas.height);
                    cursorCanvas.width = width;
                    cursorCanvas.height = height;
                    cursorCanvas.style.width = width + 'px';
                    cursorCanvas.style.height = height + 'px';

                    const context = cursorCanvas.getContext('2d');

                    if (clear) {
                        context.clearRect(0, 0, width, height);
                    }
                    context.beginPath();
                    context.moveTo(x, 30);
                    context.lineTo(x, height - 30);
                    context.lineWidth = 1;
                    context.strokeStyle = '#D7002B';
                    context.stroke();
                };
            } else {
                return (x, clear) => {};
            }
        })();

        Chart.defaults.global.defaultFontFamily = 'KievitWebBook';
        Chart.defaults.global.defaultFontColor = '#0a3174';
        Chart.defaults.global.defaultFontSize = 14;

        // Register tooltip handler for cursor bar
        Chart.Tooltip.positioners.custom = function (elements, eventPosition) {
            let clear = true;
            for (const element of elements) {
                setCursor(element._view.x, clear);
                clear = false;
            }

            return Chart.Tooltip.positioners.average(elements, eventPosition);
        };

        const context = canvas.getContext('2d');

        const chart = new Chart(context, {
            type: 'line',
            data: {
                datasets: [],
            },
            options: {
                responsive: true,
                title: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    position: 'custom',
                    backgroundColor: '#ffffff',
                    titleFontFamily: 'KievitWebBold',
                    titleFontColor: Chart.defaults.global.defaultFontColor,
                    bodyFontFamily: 'KievitWebBold',
                    bodyFontColor: Chart.defaults.global.defaultFontColor,
                    displayColors: false,
                    borderColor: '#0a3174',
                    borderWidth: 1,
                    cornerRadius: 15,
                    titleSpacing: 4,
                    bodySpacing: 4,
                    xPadding: 10,
                    yPadding: 10,
                    caretSize: 10,
                    callbacks: {
                        label: (item, data) => {
                            let label = data.datasets[item.datasetIndex].label || '';

                            if (label) {
                                label += ': ';
                            }
                            label += Math.round(item.yLabel * 100) / 100;
                            return label;
                        },
                        labelTextColor: (item, context) => {
                            return context.config.data.datasets[item.datasetIndex].backgroundColor;
                        },
                        title: (item) => {
                            const label = item[0].label || item.label;
                            const date = new Date(label);
                            return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
                        },
                    },
                },
                legend: {
                    // Hide legend but reserve space
                    labels: {
                        generateLabels: () => [
                            {text: '', fillStyle: 'transparent', lineWidth: 0},
                            {text: '', fillStyle: 'transparent', lineWidth: 0},
                        ],
                    },
                },
                hover: {
                    mode: 'nearest',
                    intersect: true,
                },

                scales: {
                    xAxes: [
                        {
                            type: 'time',
                            time: {
                                parser: 'YYYY-MM-DD',
                                displayFormats: {
                                    hour: 'MMM D', // let hour units be displayed as days instead
                                },
                            },
                            scaleLabel: {
                                display: false,
                            },
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            scaleLabel: {
                                display: false,
                            },
                            gridLines: {
                                color: '#0a3174',
                                drawBorder: false,
                            },
                        },
                    ],
                },
                elements: {
                    line: {
                        tension: 0,
                        fill: false,
                        borderWidth: 2,
                    },
                    point: {
                        radius: 0,
                    },
                },
            },
        });

        // Load data
        const updateChart = () => {
            const range = [...document.querySelectorAll('input[name=range]')].filter((item) => item.checked)[0].value;

            fetch(`/api/performance?sc=${shareClassID}&range=${range}`)
                .then((response) => response.json())
                .then((data) => {
                    chart.data.datasets = [];

                    if (data.performance && data.performance.length) {
                        chart.data.datasets.push({
                            label: 'Fonds',
                            backgroundColor: '#0069B4',
                            borderColor: '#0069B4',
                            data: data.performance,
                        });
                    }

                    if (data.benchmark && data.benchmark.length) {
                        chart.data.datasets.push({
                            label: 'Benchmark',
                            backgroundColor: '#00B0E9',
                            borderColor: '#00B0E9',
                            data: data.benchmark,
                        });
                    }

                    chart.update();
                });
        };
        updateChart();

        // Register range change handlers
        for (const input of document.querySelectorAll('input[name=range]')) {
            input.addEventListener('click', () => {
                updateChart();
            });
        }

        isSetup = true;
    };

    if (!parent || !parent.classList.contains('collapsed')) {
        setup();
    } else {
        const trigger = parent.querySelector('h2') || parent.querySelector('h3');
        if (trigger) {
            trigger.addEventListener('click', () => {
                if (!isSetup) {
                    setup();
                }
            });
        }
    }
})();
