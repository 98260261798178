// Requires jQuery

(() => {
    $('a').on('click', function (event) {
        if (this.hash === '') {
            return;
        }

        const currentURL = window.location.href;
        const targetURL = this.href.substr(0, this.href.indexOf('#'));

        const isSamePage = currentURL.indexOf(targetURL) >= 0 || targetURL.indexOf(currentURL) >= 0;

        if (isSamePage) {
            event.preventDefault();

            const hash = this.hash;
            const headerHeight = $('#mobile-header').is(':visible') ? $('#mobile-header').height() : $('header').height();

            $('html, body').animate(
                {
                    scrollTop: $(hash).offset().top - headerHeight,
                },
                300,
            );
        }
    });
})();
